import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { MdLibraryAddCheck } from 'react-icons/md'
import { ImSpinner4, ImCross } from 'react-icons/im'

const Main = () => {
    const logo = 'https://www.legendsqueue.com/Images/logo2.png';
    const flag = 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg';

    const [Name, setName] = useState('');
    const [Surname, setSurname] = useState('');
    const [Contact, setContact] = useState('');
    const [Email, setEmail] = useState('');

    const [showForm, setShowForm] = useState(true)
    const [showResults, setShowResults] = useState(false)
    const [showWait, setShowWait] = useState(false)
    const [showFail, setShowFail] = useState(false)

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (Contact.toString().substring(0, 3) !== '264') {
                alert('Please enter only contact number\'s from Namibia +264');
                return;
            }

            let data = {
                Name: Name,
                Surname: Surname,
                Contact: Contact,
                Email: Email,
            };
            setShowForm(false);
            setShowWait(true);

            let res = await fetch("https://api.legendsqueue.com/api/prebook/namibia", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            // let res = await fetch("https://localhost:44339/api/prebook/namibia", {
            //     method: "POST",
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(data)
            // });

            if (res.status === 200) {
                setShowWait(false)
                setShowResults(true);
            }
            else {
                err();
            }

        } catch (err) {
            err();
        }
    };

    function err() {
        setShowFail(true);
        setShowForm(false);
        setShowResults(false);
        setShowWait(false);
    }

    return (
        <div> <img style={{ maxWidth: '100%' }} src={window.location.origin + '/banner.jpg'} />
            <div className='container mt-5'>

                <div className='card p-5 rounded-0 shadow'>
                    <div className='text-center' style={{ display: showFail ? '' : 'none' }}>
                        < ImCross size={100} className='text-danger mb-4' />
                        <h4 className='text-black fw-light'>Pre-booking was not sent successfully, please refresh the page and try again.</h4>
                    </div>

                    <div className='text-center' style={{ display: showWait ? '' : 'none' }}>
                        < ImSpinner4 size={100} className='text-dark mb-4 spinner' />
                        <h4 className='text-black fw-light'>Please wait...</h4>
                    </div>

                    <div className='text-center' style={{ display: showResults ? '' : 'none' }}>
                        <MdLibraryAddCheck className='text-success' size={100} />
                        <h4>Pre-booking confirmed.</h4>
                        <h6 className='fw-light'>See you at the opening!!!</h6>
                        <h4 className='fw-light'>Be Legendary!!!</h4>
                    </div>
                    <Form onSubmit={handleSubmit} style={{ display: showForm ? '' : 'none' }}>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control required value={Name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter name" />
                                </Form.Group>
                            </div>
                            <div className='col-sm-6'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Surname</Form.Label>
                                    <Form.Control required value={Surname} onChange={(e) => setSurname(e.target.value)} type="text" placeholder="Enter surname" />
                                </Form.Group>
                            </div>
                            <div className='col-sm-6'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Contact number</Form.Label>
                                    <Form.Control required value={Contact} onChange={(e) => setContact(e.target.value)} type="number" placeholder="Enter contact (264)" />
                                    <Form.Text className="text-muted">
                                        Restricted to Namibian contact numbers only
                                    </Form.Text>
                                </Form.Group>
                            </div>
                            <div className='col-sm-6'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control required value={Email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email" />
                                </Form.Group>
                            </div>
                        </div>

                        <Button variant="dark" className='rounded-0' type="submit">
                            JOIN QUEUE
                        </Button>
                    </Form>
                </div>
            </div>
        </div>

    )
}

export default Main
